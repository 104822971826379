// Nav显示&收起
export const SET_NAV_SHOW="setNavShow";
export const SET_NAV_HIDDEN="setNavHidden";

// 深色模式
export const SET_DARKMODE_OFF="setDarkmodeOff";
export const SET_DARKMODE_ON="setDarkmodeOn";

// 文章列表
export const SAVE_POST_LIST="savePostList";
export const CLEAR_POST_LIST="clearPostList"

//选中文章配置
export const SAVE_SELECTED_POST_CONFIG="saveSelectedPostConfig";
export const CLEAR_SELECTED_POST_CONFIG="clearSelectedPostConfig";

// 选中文章内容
export const SAVE_SELECTED_POST_HTML="saveSelectedPostHtml";
export const CLEAR_SELECTED_POST_HTML="clearSelectedPostHtml";

//TagsListReducer Action types
export const SAVE_TAGS_LIST="saveTagsList";
export const CLEAR_TAGS_LIST="clearTagsList";

//CategoriesListReducer Action types
export const SAVE_CATEGORIES_LIST="saveCategoriesList";
export const CLEAR_CATEGORIES_LIST="clearCategoriesList";

// aplayerReducer Action types
export const SAVE_APLAYER="saveAPlayer";
export const CLEAR_APLAYER="clearAPlayer";

// friendsUrlData Action types
export const SAVE_FRIENDS_URL_DATA="saveFriendsUrlData";
export const CLEAR_FRIENDS_URL_DATA="clearFriendsUrlData";

// githubRepoCommits Action types
export const SAVE_GITHUB_REPO_COMMITS:string="saveGithubRepoCommits";
export const CLEAR_GITHUB_REPO_COMMITS:string="clearGithubRepoCommits";
